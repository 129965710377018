<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :loading="isReportLoading"
      :items="tableData"
      :options.sync="options"
      :server-items-length="reportTotalRecords"
      :footer-props="{ itemsPerPageOptions: [25, 50, 100, 500] }"
      item-key="itemKey"
      multi-sort
      show-select
    >
      <template #top>
        <v-row>
          <v-col>
            <v-subheader>(*) - {{ $t('tableTab.subheader') }}</v-subheader>
          </v-col>

          <v-spacer/>

          <v-col cols="auto">
            <v-btn
              v-show="selected.length"
              x-small
              text
              @click="selected = []"
            >{{ $t('buttons.resetSelecting') }}</v-btn>
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-badge
                  :content="selected.length"
                  :value="selected.length"
                  color="warning"
                  class="mx-5"
                  bordered
                  bottom
                  overlap
                >
                  <v-btn
                    color="primary"
                    fab
                    outlined
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="exportTable"
                  >
                    <v-icon>mdi-file-export</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <span>{{ $t('tableTab.exportTooltip') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>

      <template #header.vehicle_class="{ header }">
        {{ $t(header.text) }}
      </template>
      <template #header.rental_period="{ header }">
        {{ $t(header.text) }}
      </template>
      <template #header.pickup_date="{ header }">
        {{ $t(header.text) }}
      </template>
      <template #header.KW="{ header }">
        {{ $t(header.text) }}
      </template>
      <template #header.median_pay_now="{ header }">
        {{ $t(header.text) }}
      </template>
      <template #header.median_pay_later="{ header }">
        {{ $t(header.text) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';

export default {
  name: 'Table',
  data: () => ({
    defaultHeaders: [
      { text: 'tableTab.headers.vehicleClass', value: 'vehicle_class', align: 'left' },
      {
        text: 'tableTab.headers.rentalPeriod', value: 'rental_period', align: 'center', width: '1%',
      },
      {
        text: 'Region', value: 'location', align: 'center', width: '1%',
      },
      { text: 'tableTab.headers.pickupDate', value: 'pickup_date', align: 'center' },
      { text: 'tableTab.headers.kw', value: 'KW', align: 'center' },
      { text: 'tableTab.headers.medianPayNow', value: 'median_pay_now', align: 'center' },
      { text: 'tableTab.headers.medianPayLater', value: 'median_pay_later', align: 'center' },
    ],
    options: {
      sortBy: ['location', 'rental_period', 'pickup_date', 'vehicle_class'],
    },
    mountedFlag: false,
    exportData: {
      columns: [],
      data: [],
      filename: null,
    },
    selected: [],
  }),
  computed: {
    ...mapState(['isReportLoading', 'reportData',
      'search', 'reportTotalRecords', 'portals']),

    debounceRunSearchQuery() {
      return debounce(this.getItems, this.$store.getters.APITimeout);
    },

    headers() {
      const { defaultHeaders } = this;
      this.portals.forEach((portal, index) => {
        const text = portal.replace(/rent_(.+)_de/, '$1 (*)');
        defaultHeaders.splice(5 + index, 0, {
          text, value: portal, align: 'center', sortable: false,
        });
      });
      return defaultHeaders;
    },

    tableData() {
      const processed = [];
      this.reportData.forEach((item) => {
        const record = {
          vehicle_class: item.vehicle_class,
          rental_period: item.rental_period,
          location: item.location,
          pickup_date: moment(item.pickup_date).format('YYYY-MM-DD'),
          KW: item.KW,
          median_pay_now: item.median_pay_now,
          median_pay_later: item.median_pay_later,
        };
        record.itemKey = [item.vehicle_class, item.rental_period,
          item.location, item.pickup_date].join('::');
        // eslint-disable-next-line camelcase
        item.grouped_prices.forEach(({ source, avg_pay_now, avg_pay_later }) => {
          // eslint-disable-next-line camelcase
          record[source] = `${avg_pay_now || '-'} / ${avg_pay_later || '-'}`;
        });
        processed.push(record);
      });
      return processed;
    },
  },
  watch: {
    options: {
      handler() {
        this.debounceRunSearchQuery();
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on('table', () => {
      this.debounceRunSearchQuery();
      this.mountedFlag = true;
    });
  },
  methods: {
    ...mapActions(['getReport']),

    getItems() {
      if (!this.mountedFlag) return;
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      let sorter;
      if (sortBy.length) {
        const sorterList = [];
        for (let i = sortBy.length - 1; i >= 0; i -= 1) {
          sorterList.push(`${sortBy[i]} ${(sortDesc && sortDesc[i] ? 'desc' : 'asc')}`);
        }
        sorter = sorterList.join(',');
      }
      this.getReport({
        search: { ...this.search },
        pagination: { sorter, page, limit: itemsPerPage },
      });
    },

    exportTable() {
      if (!this.selected.length) return;
      const actualHeaders = {};
      this.headers.forEach(({ value }) => {
        actualHeaders[value] = true;
      });
      const date = moment(moment.now()).format('YYYY-MM-DD_HH-MM-SS');
      const fileName = `meiteauto_export_table-${date}`;
      const workSheepData = [
        this.headers.map(({ text }) => (text.includes('tableTab.header') ? this.$t(text) : text)),
        // eslint-disable-next-line no-unused-vars
        ...this.selected.map((item) => Object.entries(actualHeaders).map(([key, _]) => item[key])),
      ];
      const invoicesWS = utils.aoa_to_sheet(workSheepData);

      const wb = utils.book_new();

      utils.book_append_sheet(wb, invoicesWS, 'mietauto table');

      writeFile(wb, `${fileName}.xlsx`);
    },
  },
};
</script>
